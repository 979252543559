<template>
	<div>
		<h1><em class="fas fa-lock"></em> Customer Portal</h1>

		<div class="row">
			<div class="col-12 col-lg-6 mb-3">
				<div class="card text-center">
					<div class="card-header"><h2 class="m-0 p-0"><em class="fas fa-fw fa-key"></em> User Login</h2></div>

					<div class="card-body">
						<div class="form-row">
							<div class="alert alert-info d-block w-100" v-if="login_loading"><em class="fas fa-circle-notch fa-spin"></em> Please Wait...</div>
							<div class="alert alert-success d-block w-100" v-if="login_message">{{login_message}}</div>
							<div class="alert alert-danger d-block w-100" v-if="login_error">{{login_error}}</div>
						</div>

						<form method="post" v-on:submit.prevent="login()">
							<div class="form-row mb-2">
								<input type="text" v-model="creds.email" v-bind:disabled="login_loading == 1" placeholder="Email Address" title="Email Address" class="form-control" aria-label="Your email address is required." required autofocus autocomplete="off" />
							</div>

							<div class="form-row mb-2">
								<input type="password" id="password" v-model="creds.password" v-bind:disabled="login_loading == 1" placeholder="Password" title="Password" class="form-control" aria-label="Your password is required." required />
							</div>

							<div class="form-row">
								<button type="submit" class="btn btn-primary d-block w-100" v-bind:disabled="login_loading == 1"><em class="fas fa-fw fa-sign-in-alt" aria-hidden="true"></em> Authenticate</button>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div class="col-12 col-lg-6 mb-3">
				<div class="card text-center">
					<div class="card-header"><h2 class="m-0 p-0"><em class="fas fa-fw fa-question-circle"></em> Forgot Your Password?</h2></div>

					<div class="card-body">
						<div class="form-row">
							<div class="alert alert-info d-block w-100" v-if="reset_loading"><em class="fas fa-circle-notch fa-spin"></em> Please Wait...</div>
							<div class="alert alert-success d-block w-100" v-if="reset_message">{{reset_message}}</div>
							<div class="alert alert-danger d-block w-100" v-if="reset_error">{{reset_error}}</div>
						</div>

						<form method="post" v-on:submit.prevent="passwordResetEmail" v-show="reset_step == 1">
							<div class="form-row mb-2">
								<input type="email" v-model="reset.email" v-bind:disabled="reset_step == 1 && reset_loading == 1" placeholder="Email Address" title="Email Address" class="form-control" aria-label="Your email address can be used to reset your password." required autocomplete="off" />
							</div>

							<div class="form-row mb-2">
								<input type="text" class="form-control" style="visibility: hidden" />
							</div>

							<div class="form-row">
								<button type="submit" class="btn btn-info d-block w-100" v-bind:disabled="reset_loading == 1">Continue <em class="fas fa-fw fa-arrow-right" aria-hidden="true"></em></button>
							</div>
						</form>

						<form method="post" v-on:submit.prevent="passwordReset" v-show="reset_step == 2">
							<div class="form-row mb-2">
								<input type="email" v-model="reset.email" v-bind:disabled="reset_loading == 1" placeholder="Email Address" title="Email Address" class="form-control" aria-label="Your email address can be used to reset your password." required autocomplete="off" />
							</div>

							<div class="form-row mb-2">
								<input type="text" id="password_reset_code" v-model="reset.code" v-bind:disabled="reset_loading == 1" placeholder="Password Reset Code" title="Password Reset Code" class="form-control" aria-label="You were emailed a password reset code." required autocomplete="off" />
							</div>

							<div class="form-row mb-2">
								<input type="password" v-model="reset.password" v-bind:disabled="reset_loading == 1" placeholder="New Password" title="New Password" class="form-control" aria-label="Enter your new password." required autocomplete="off" minlength="8" />
							</div>

							<div class="form-row">
								<button type="submit" class="btn btn-success d-block w-100" v-bind:disabled="reset_step == 2 && reset_loading == 1"><em class="fas fa-fw fa-magic" aria-hidden="true"></em> Reset Password</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-12 mb-3">
				<div class="card text-center">
					<div class="card-header"><h2 class="m-0 p-0"><em class="fas fa-fw fa-user-plus"></em> New Users, Start Here</h2></div>

					<div class="card-body">
						<div class="form-row">
							<div class="alert alert-info d-block w-100" v-if="reg_loading"><em class="fas fa-circle-notch fa-spin"></em> Please Wait...</div>
							<div class="alert alert-success d-block w-100" v-if="reg_message">{{reg_message}}</div>
							<div class="alert alert-danger d-block w-100" v-if="reg_error">{{reg_error}}</div>
						</div>

						<div class="text-left">
							<form method="post" v-on:submit.prevent="register1()" v-if="reg_step == 1">
								<p class="text-center"><em class="fas fa-info-circle text-info"></em> To create an account on our portal we need to lookup your account using your phone number and email address on file.</p>

								<div class="form-row mb-2">
									<div class="col-12 col-md-6"><input type="text" v-model="reg.cell" v-bind:disabled="reg_loading == 1" placeholder="Phone Number" title="Phone Number" class="form-control" aria-label="Your cell phone number is required." autocomplete="off" required /></div>
									<div class="col-12 col-md-6"><input type="email" v-model="reg.email" v-bind:disabled="reg_loading == 1" placeholder="Email Address" title="Email Address" class="form-control" aria-label="Your email address is required." autocomplete="off" required /></div>
								</div>

								<div class="form-row">
									<div class="col-12"><button type="submit" class="btn btn-success d-block w-100" v-bind:disabled="reg_loading == 1">Continue <em class="fas fa-fw fa-arrow-right" aria-hidden="true"></em></button></div>
								</div>
							</form>
							<form method="post" v-on:submit.prevent="register2()" v-if="reg_step == 2">
								<p class="text-center"><em class="fas fa-info-circle text-info"></em> Next we need to send you a text message or an email to confirm your identity. Please choose an option.</p>

								<div class="form-row mb-2">
									<div class="col-12 col-md-4 text-center" v-show="reg_customer.c_phone != ''">
										<button class="btn btn-success" @click="register2('c_phone')"><em class="fas fa-mobile-alt"></em> Text me at {{reg_customer.c_phone}}</button>
									</div>
									<div class="col-12 col-md-4 text-center" v-show="reg_customer.c_phone != ''">
										<button class="btn btn-success" @click="register2('h_phone')"><em class="fas fa-mobile-alt"></em> Text me at {{reg_customer.h_phone}}</button>
									</div>
									<div class="col-12 col-md-4 text-center" v-show="reg_customer.email != ''">
										<button class="btn btn-success" @click="register2('email')"><em class="fas fa-envelope"></em> Email me at {{reg_customer.email}}</button>
									</div>
									<div class="col-12 text-center" v-show="reg_customer.h_phone == '' && reg_customer.c_phone == '' && reg_customer.email == ''">
										<p>The customer found does not have a cell phone or email address on file. Please call us.</p>
									</div>
								</div>
								<div class="form-row">
									<div class="col-12"><button type="submit" class="btn btn-success d-block w-100" v-bind:disabled="reg_loading == 1">Continue <em class="fas fa-fw fa-arrow-right" aria-hidden="true"></em></button></div>
								</div>
							</form>
							<form method="post" v-on:submit.prevent="register3()" v-if="reg_step == 3">
								<p class="text-center"><em class="fas fa-info-circle text-info"></em> Lastly, we need to verify your confirmation code to create your account.</p>

								<div class="form-row mb-2">
									<div class="col-12 col-md-4"><label>Confirmation Code</label></div>
									<div class="col-12 col-md-8"><input type="text" v-model="reg.confirmation_code" v-bind:disabled="reg_loading == 1" placeholder="Confirmation Code" title="Confirmation Code" class="form-control" aria-label="Your policy number is required." required autocomplete="off" /></div>
								</div>

								<div class="form-row">
									<div class="col-12"><button type="submit" class="btn btn-success d-block w-100" v-bind:disabled="reg_loading == 1">Continue <em class="fas fa-fw fa-arrow-right" aria-hidden="true"></em></button></div>
								</div>
							</form>
							<div v-if="reg_step == 4">
								<div class="form-row mb-2">
									<div class="col-12 col-md-4"><label>Email Address</label></div>
									<div class="col-12 col-md-8 text-monospace">{{reg_customer.email}}</div>
								</div>

								<div class="form-row mb-2">
									<div class="col-12 col-md-4"><label>Password</label></div>
									<div class="col-12 col-md-8 text-monospace">{{reg_customer.password}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Login',
	props: ["session_id", "logged_in"],
	data() {
		return {
			creds: {email: "", password: ""},
			reg: {cell: "", email: ""},
			reg_step: 1,
			reg_customer: {},
			reset: {email: "", code: "", password: ""},
			login_loading: false,
			login_message: "",
			login_error: "",
			reg_loading: false,
			reg_message: "",
			reg_error: "",
			reset_loading: false,
			reset_message: "",
			reset_error: "",
			reset_step: 1
		}
	},
	mounted: function()
	{
		// this.$emit("setSessionID", "abcdefg");
	},
	methods:
	{
		login: function()
		{
			var vm = this;
			vm.login_message = "";
			vm.login_error = "";
			vm.login_loading = true;
			vm.startLoading();

			vm.CORS("POST", "/login", JSON.stringify(vm.creds),
				function(data)
				{
					vm.login_loading = false;
					vm.stopLoading();

					if(data.success == 1)
					{
						vm.$emit("setlogin", true);
						vm.$emit("setsessionid", data.session_id);
						vm.$router.push("/");
					}
					else
					{
						vm.$emit("setlogin", true);
						vm.$emit("setsessionid", "");
						vm.reset_error = "Login Failed";
					}
				},
				function(data)
				{
					vm.login_loading = false;
					vm.stopLoading();

					vm.login_error = data.responseJSON.message;
					vm.$emit("setlogin", false);
					vm.$emit("setsessionid", "");
				});
		},
		register1: function()
		{
			var vm = this;
			vm.reg_message = "";
			vm.reg_error = "";
			vm.reg_loading = true;
			vm.startLoading();

			if(vm.reg.cell == "" && vm.reg.email == "")
			{
				vm.reg_loading = "You must enter your phone number and email address on file to confirm your identity.";
				vm.reg_loading = false;
				return false;
			}

			vm.CORS("POST", "/checkid", JSON.stringify(vm.reg),
				function(data)
				{
					vm.reg_loading = false;
					vm.stopLoading();
					vm.reg_customer = data;
					vm.reg_step = 2;
				},
				function(data)
				{
					vm.reg_loading = false;
					vm.stopLoading();
					vm.reg_error = data.responseText;
				});
		},
		register2: function(sendto)
		{
			if(typeof sendto == "undefined")
			{
				return false;
			}
			// else if(sendto == "h_phone" || sendto == "c_phone")
			// {
			// 	sendto = "cell";
			// }

			var vm = this;
			vm.reg_message = "";
			vm.reg_error = "";
			vm.reg_loading = true;
			vm.startLoading();

			vm.CORS("GET", "/confirm_" + sendto + "/" + vm.reg_customer.customer_id, null,
				function(data)
				{
					vm.reg_loading = false;
					vm.stopLoading();
					vm.reg_message = data;
					vm.reg_step = 3;
				},
				function(data)
				{
					vm.reg_loading = false;
					vm.stopLoading();
					vm.reg_error = data.responseText;
				});
		},
		register3: function()
		{
			var vm = this;
			vm.reg_message = "";
			vm.reg_error = "";
			vm.reg_loading = true;
			vm.startLoading();

			var join = {"customer_id": vm.reg_customer.customer_id, "claim_code": vm.reg.confirmation_code};

			vm.CORS("POST", "/join", JSON.stringify(join),
				function(data)
				{
					vm.reg_loading = false;
					vm.stopLoading();
					vm.reg_message = "Thank you, your account has been created. Please save the login information below somewhere safe and then use it to login above.";
					vm.reg_customer = data;
					vm.reg_step = 4;
				},
				function(data)
				{
					vm.reg_loading = false;
					vm.stopLoading();
					vm.reg_error = data.responseText;
				});
		},
		passwordResetEmail: function()
		{
			var vm = this;
			vm.reset_message = "";
			vm.reset_error = "";
			vm.reset_loading = true;
			vm.startLoading();

			vm.CORS("POST", "/passwordReset", vm.reset,
				function(data)
				{
					vm.reset_loading = false;
					vm.stopLoading();

					if(data.success == 1)
					{
						vm.reset_message = data.message;
						vm.reset_step = 2;
						setTimeout(function()
						{
							document.getElementById("password_reset_code").focus();
						}, 500);
					}
					else
					{
						vm.reset_error = "Password Reset Failed";
					}
				},
				function(data)
				{
					vm.reset_loading = false;
					vm.stopLoading();
					vm.reset_error = data.responseJSON.message;
				});
		},
		passwordReset: function()
		{
			var vm = this;
			vm.reset_message = "";
			vm.reset_error = "";
			vm.reset_loading = true;
			vm.startLoading();

			vm.CORS("PUT", "/passwordReset", vm.reset,
				function(data)
				{
					vm.reset_loading = false;
					vm.stopLoading();

					if(data.success == 1)
					{
						vm.reset_message = data.message;
						vm.reset_step = 3;
						vm.creds.email = data.email;
						vm.creds.password = vm.reset.password;

						setTimeout(function()
						{
							document.getElementById("password").focus();
						}, 500);
					}
					else
					{
						vm.reset_error = "Password Reset Failed";
					}
				},
				function(data)
				{
					vm.reset_loading = false;
					vm.stopLoading();
					vm.reset_error = data.responseJSON.message;
				});
		}
	}
}
</script>
